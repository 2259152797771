
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import InsightsWhite from '@/components/creditpushMain/HomePage/InsightsWhite.vue';
import Integrations from '@/components/creditpushMain/HomePage/Integrations.vue';
import LandingHero from '@/components/landingAndRegistrationComponents/LandingHero.vue';
import LandingSteps from '@/components/landingAndRegistrationComponents/LandingSteps.vue';
import { Component, Vue } from 'vue-property-decorator';
import { getNextPage } from '@/utils/redirectUtils';
import { mapState } from 'vuex';

@Component({
  components: {
    BaseButton,
    LandingHero,
    LandingSteps,
    Integrations,
    InsightsWhite,
  },
  computed: {
    ...mapState('calculateWindowWidth', ['windowWidth']),
  },
})
export default class LandingLoanPage extends Vue {
  public windowWidth!: number;

  get pictureSrc(): string {
    return this.windowWidth <= 763 ? '_mobile' : '';
  }

  goToRegistration(): void {
    this.$router.push({ name: getNextPage() });
  }

  mounted(): void {
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '107', pageName: this.$route.name });
  }
}
